<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-row>
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Bienvenido al sistema de Ticket! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Por favor ingrese el correo
            </b-card-text>

            <!-- form -->
            <validation-observer ref="formValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  label="Correo"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@crsoporte.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password">Contraseña</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="contraseña"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="hasCode"
                  class="animated animate__fadeIn"
                  label="Código de verificación"
                  label-for="code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="código"
                    rules="required"
                  >
                    <b-form-input
                      id="code"
                      v-model="code"
                      :state="errors.length > 0 ? false:null"
                      name="code"
                      placeholder="Ingrese código de verificación"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div
                    class="cursor-pointer text-primary text-decoration-underline mt-1 text-center"
                    @click="onHandleResend"
                  >
                    Reenviar código
                  </div>
                </b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="onHandleValidationForm"
                >
                  Iniciar Sesión
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import errorsServices from '@/libs/errorsServices'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// captcha
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY })

export default {
  name: 'LoginPage',
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      hasCode: false,
      status: '',
      userEmail: '',
      password: '',
      code: 0,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.hasCode = false
      this.userEmail = ''
      this.password = ''
      this.code = 0
      this.$refs.formValidation.reset()
    },
    async onHandleResend() {
      this.code = 0
      this.hasCode = false
      await this.onHandleValidationForm()
    },
    async onHandleValidationForm() {
      const recaptcha = await this.$recaptcha('login')
      const recaptchaInstance = this.$recaptchaInstance
      recaptchaInstance.showBadge()

      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            email: this.userEmail,
            password: this.password,
            recaptcha,
            code: this.code,
          }

          useJwt.login(param).then(({ data }) => {
            recaptchaInstance.hideBadge()
            useJwt.setToken(data.access_token)
            useJwt.me().then(resp => {
              useJwt.setCompanies(resp.data.companies)
              const companySelected = useJwt.getCompanySelected()

              if (companySelected) {
                useJwt.setCompanySelected(companySelected)
              } else {
                useJwt.setCompanySelected(resp.data.companies[0])
              }

              this.clearForm()
              useJwt.setUserData(resp.data)
              router.push({ name: 'main' })
            })
          }).catch(error => {
            if (error.response.data.error === 'No Autorizado, revise su bandeja de entrada en el correo') {
              this.code = ''
              this.hasCode = true
            } else if (error.response.data.error === 'No Autorizado, el tiempo limite para el reenvio del código es de 1 minuto.') {
              this.swal(error.response.data.error, 'error')
            } else {
              this.code = 0
              this.hasCode = false
              this.errorResp(error)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.text-decoration-underline {
  text-decoration: underline !important;
}
</style>
